import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { DEBUG } from './config';

// Функция для инициализации Яндекс Карт
const loadYandexMaps = () => {
  const script = document.createElement('script');
  const apiKey = process.env.REACT_APP_YANDEX_MAPS_API_KEY || '';
  script.src = `https://api-maps.yandex.ru/2.1/?apikey=${apiKey}&lang=ru_RU`;
  script.async = true;
  document.head.appendChild(script);
  
  if (DEBUG) {
    console.log('Загрузка Яндекс Карт...');
    script.onload = () => console.log('Яндекс Карты загружены успешно');
    script.onerror = (error) => console.error('Ошибка при загрузке Яндекс Карт:', error);
  }
};

// Загружаем Яндекс Карты перед рендерингом приложения
loadYandexMaps();

// Инициализация приложения
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Вывод информации о режиме разработки
if (DEBUG) {
  console.log('Приложение запущено в режиме отладки');
  console.log('Переменные окружения:', {
    NODE_ENV: process.env.NODE_ENV,
    API_URL: process.env.REACT_APP_API_URL,
    BOT_USERNAME: process.env.REACT_APP_BOT_USERNAME,
    DEBUG: process.env.REACT_APP_DEBUG
  });
}