// Конфигурация приложения PinGram

// Режим разработки (true) или продакшн (false)
export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

// Включить отладочное логирование
export const DEBUG = IS_DEVELOPMENT;

// URL API-сервера
export const API_URL = IS_DEVELOPMENT 
  ? 'http://localhost:8000/api' // URL для разработки
  : '/api'; // URL для продакшна (относительный путь, т.к. фронтенд и API на одном домене)

// Настройки карты
export const DEFAULT_MAP_CENTER = [55.751244, 37.618423]; // Москва по умолчанию
export const DEFAULT_ZOOM = 10;

// Проверка на HTTPS
export const IS_HTTPS = window.location.protocol === 'https:';

// API-ключ Яндекс Карт
export const YANDEX_MAPS_API_KEY = process.env.REACT_APP_YANDEX_MAPS_API_KEY || '';

// Интервал обновления местоположений (в миллисекундах)
export const UPDATE_INTERVAL = 60000; // 1 минута

// Настройки приватности - опции скрытия
export const HIDE_OPTIONS = [
  { label: '1 час', value: 60 * 60 * 1000 },
  { label: '3 часа', value: 3 * 60 * 60 * 1000 },
  { label: '12 часов', value: 12 * 60 * 60 * 1000 },
  { label: '24 часа', value: 24 * 60 * 60 * 1000 }
];

// Мок-данные для тестирования
export const MOCK_SETTINGS = {
  isHidden: false,
  hiddenUntil: null
};

// Мок-данные друзей для тестирования
export const MOCK_FRIENDS = [
  {
    id: 1,
    user_id: 1,
    telegram_id: 111111,
    first_name: 'Иван',
    last_name: 'Иванов',
    username: 'ivanov',
    avatar_url: 'https://via.placeholder.com/50/FF5733/FFFFFF?text=ИИ',
    latitude: 55.753215,
    longitude: 37.622504,
    last_seen: new Date().toISOString(),
    distance: 1200
  },
  {
    id: 2,
    user_id: 2,
    telegram_id: 222222,
    first_name: 'Мария',
    last_name: 'Петрова',
    username: 'petrova',
    avatar_url: 'https://via.placeholder.com/50/33A8FF/FFFFFF?text=МП',
    latitude: 55.746442,
    longitude: 37.608013,
    last_seen: new Date(Date.now() - 1000 * 60 * 30).toISOString(), // 30 минут назад
    distance: 3500
  },
  {
    id: 3,
    user_id: 3,
    telegram_id: 333333,
    first_name: 'Алексей',
    last_name: 'Смирнов',
    username: 'smirnov',
    avatar_url: 'https://via.placeholder.com/50/33FF57/FFFFFF?text=АС',
    latitude: 55.758863,
    longitude: 37.632481,
    last_seen: new Date(Date.now() - 1000 * 60 * 5).toISOString(), // 5 минут назад
    distance: 800
  }
];

// Максимальная длина текстовых полей
export const MAX_USERNAME_LENGTH = 30;
export const MAX_MESSAGE_LENGTH = 200;