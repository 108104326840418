import React, { useEffect, useState } from 'react';
import { useTelegram } from '../App';
import '../App.css';
import { API_URL, IS_DEVELOPMENT, DEBUG } from '../config';

// Локальные тестовые данные на случай, если их нет в config.js
const LOCAL_MOCK_FRIENDS = [
  {
    id: 1,
    user_id: 1,
    telegram_id: 111111,
    first_name: 'Иван',
    last_name: 'Иванов',
    username: 'ivanov',
    avatar_url: 'https://via.placeholder.com/50/FF5733/FFFFFF?text=ИИ',
    latitude: 55.753215,
    longitude: 37.622504,
    last_seen: new Date().toISOString(),
    distance: 1200
  },
  {
    id: 2,
    user_id: 2,
    telegram_id: 222222,
    first_name: 'Мария',
    last_name: 'Петрова',
    username: 'petrova',
    avatar_url: 'https://via.placeholder.com/50/33A8FF/FFFFFF?text=МП',
    latitude: 55.746442,
    longitude: 37.608013,
    last_seen: new Date(Date.now() - 1000 * 60 * 30).toISOString(),
    distance: 3500
  },
  {
    id: 3,
    user_id: 3,
    telegram_id: 333333,
    first_name: 'Алексей',
    last_name: 'Смирнов',
    username: 'smirnov',
    avatar_url: 'https://via.placeholder.com/50/33FF57/FFFFFF?text=АС',
    latitude: 55.758863,
    longitude: 37.632481,
    last_seen: new Date(Date.now() - 1000 * 60 * 5).toISOString(),
    distance: 800
  }
];

const FriendsPage = () => {
  const { telegramWebApp, user } = useTelegram();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [friends, setFriends] = useState([]);

  const fetchFriends = async () => {
    try {
      const initData = telegramWebApp?.initData || '';
      let requestHeaders = {
        'Content-Type': 'application/json'
      };
      
      // Добавляем заголовок Authorization только если initData не пуст
      if (initData) {
        requestHeaders['Authorization'] = `Bearer ${initData}`;
      }

      if (DEBUG) {
        console.log('Запрос на получение списка друзей', `${API_URL}/friends/list`);
        console.log('Headers:', requestHeaders);
      }
      
      const response = await fetch(`${API_URL}/friends/list`, {
        method: 'GET',
        headers: requestHeaders,
        credentials: 'include' // Добавляем для поддержки cookie-аутентификации
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      if (DEBUG) {
        console.log('Получен ответ от API:', data);
      }
      
      setFriends(data.friends || []);
      setError(null);
    } catch (err) {
      console.error('Ошибка при загрузке списка друзей:', err);
      
      // В режиме разработки или отладки используем тестовые данные
      if (IS_DEVELOPMENT || DEBUG) {
        if (DEBUG) {
          console.log('Используем тестовые данные друзей в режиме отладки');
        }
        // Используем локальные тестовые данные
        setFriends(LOCAL_MOCK_FRIENDS);
        setError(null);
      } else {
        setError(`Ошибка при загрузке списка друзей: ${err.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFriends();
  }, [telegramWebApp]);

  const renderFriend = (friend) => {
    // Форматирование последней активности
    const formatLastSeen = (lastSeen) => {
      if (!lastSeen) return 'Нет данных';
      
      const lastSeenDate = new Date(lastSeen);
      const now = new Date();
      const diffMs = now - lastSeenDate;
      const diffMins = Math.floor(diffMs / 60000);
      
      if (diffMins < 1) {
        return 'Онлайн';
      } else if (diffMins < 60) {
        return `${diffMins} мин. назад`;
      } else {
        const diffHours = Math.floor(diffMins / 60);
        if (diffHours < 24) {
          return `${diffHours} ч. назад`;
        } else {
          return `${Math.floor(diffHours / 24)} д. назад`;
        }
      }
    };

    // Форматирование расстояния
    const formatDistance = (distance) => {
      if (!distance && distance !== 0) return 'Нет данных';
      
      if (distance < 1000) {
        return `${Math.round(distance)} м`;
      } else {
        return `${(distance / 1000).toFixed(1)} км`;
      }
    };

    return (
      <div key={friend.id || friend.user_id} className="friend-item">
        <div className="friend-avatar">
          <img src={friend.avatar_url || 'https://via.placeholder.com/50'} alt={friend.first_name} />
        </div>
        <div className="friend-info">
          <div className="friend-name">
            {friend.first_name} {friend.last_name || ''}
          </div>
          <div className="friend-status">
            <span>Последняя активность: {formatLastSeen(friend.last_seen || friend.last_updated)}</span>
          </div>
          {friend.distance !== undefined && (
            <div className="friend-distance">
              <span>Расстояние: {formatDistance(friend.distance)}</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (loading) {
    return <div className="loading">Загрузка списка друзей...</div>;
  }

  return (
    <div className="friends-page">
      {error && (
        <div className="error-banner">
          {error}
          <button 
            className="retry-button" 
            onClick={() => {
              setError(null);
              setLoading(true);
              // Перезагрузка списка друзей
              fetchFriends();
            }}
          >
            Повторить
          </button>
        </div>
      )}
      
      {friends.length === 0 ? (
        <div className="no-friends">
          <p>У вас пока нет друзей в PinGram.</p>
          <p>Добавить друзей можно через команду /friends в Telegram боте.</p>
        </div>
      ) : (
        <div className="friends-list">
          {friends.map(renderFriend)}
        </div>
      )}
      
      <style jsx>{`
        .friends-page {
          padding: 15px;
          max-width: 600px;
          margin: 0 auto;
        }
        
        .error-banner {
          background-color: rgba(244, 67, 54, 0.9);
          color: white;
          border-radius: 8px;
          padding: 10px;
          margin-bottom: 15px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
        
        .retry-button {
          background-color: white;
          color: #f44336;
          border: none;
          border-radius: 4px;
          padding: 5px 10px;
          font-weight: bold;
          cursor: pointer;
          align-self: flex-end;
        }
        
        .friends-list {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }
        
        .friend-item {
          display: flex;
          background-color: white;
          border-radius: 10px;
          padding: 10px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
        
        .friend-avatar {
          width: 50px;
          height: 50px;
          margin-right: 15px;
        }
        
        .friend-avatar img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
        
        .friend-info {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        
        .friend-name {
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 5px;
        }
        
        .friend-status, .friend-distance {
          font-size: 14px;
          color: #666;
          margin-bottom: 3px;
        }
        
        .no-friends {
          text-align: center;
          padding: 20px;
          color: #666;
        }
      `}</style>
    </div>
  );
};

export default FriendsPage;