import React, { useEffect, useState, useRef } from 'react';
import { useTelegram } from '../App';
import '../App.css';
import { 
  API_URL, 
  DEFAULT_MAP_CENTER, 
  DEFAULT_ZOOM, 
  IS_DEVELOPMENT, 
  DEBUG,
  IS_HTTPS,
  YANDEX_MAPS_API_KEY,
  UPDATE_INTERVAL
} from '../config';

// Локальные тестовые данные для режима разработки
const LOCAL_MOCK_FRIENDS = [
  {
    id: 1,
    user_id: 1,
    telegram_id: 111111,
    first_name: 'Иван',
    last_name: 'Иванов',
    username: 'ivanov',
    avatar_url: 'https://via.placeholder.com/50/FF5733/FFFFFF?text=ИИ',
    latitude: 55.753215,
    longitude: 37.622504,
    last_seen: new Date().toISOString(),
    distance: 1200
  },
  {
    id: 2,
    user_id: 2,
    telegram_id: 222222,
    first_name: 'Мария',
    last_name: 'Петрова',
    username: 'petrova',
    avatar_url: 'https://via.placeholder.com/50/33A8FF/FFFFFF?text=МП',
    latitude: 55.746442,
    longitude: 37.608013,
    last_seen: new Date(Date.now() - 1000 * 60 * 30).toISOString(),
    distance: 3500
  },
  {
    id: 3,
    user_id: 3,
    telegram_id: 333333,
    first_name: 'Алексей',
    last_name: 'Смирнов',
    username: 'smirnov',
    avatar_url: 'https://via.placeholder.com/50/33FF57/FFFFFF?text=АС',
    latitude: 55.758863,
    longitude: 37.632481,
    last_seen: new Date(Date.now() - 1000 * 60 * 5).toISOString(),
    distance: 800
  }
];

// Форматирование даты последнего обновления
const formatLastSeen = (isoDate) => {
  if (!isoDate) return 'неизвестно';
  
  const date = new Date(isoDate);
  const now = new Date();
  const diffMinutes = Math.floor((now - date) / (1000 * 60));
  
  if (diffMinutes < 1) return 'только что';
  if (diffMinutes < 60) return `${diffMinutes} мин. назад`;
  
  const hours = Math.floor(diffMinutes / 60);
  if (hours < 24) return `${hours} ч. назад`;
  
  return date.toLocaleString('ru-RU', { 
    day: 'numeric', 
    month: 'short', 
    hour: 'numeric', 
    minute: 'numeric'
  });
};

// Форматирование расстояния
const formatDistance = (meters) => {
  if (!meters && meters !== 0) return 'неизвестно';
  
  if (meters < 1000) {
    return `${Math.round(meters)} м`;
  } else {
    return `${(meters / 1000).toFixed(1)} км`;
  }
};

const MapPage = () => {
  const { telegramWebApp, user } = useTelegram();
  const [yMapsReady, setYMapsReady] = useState(false);
  const [location, setLocation] = useState(DEFAULT_MAP_CENTER);
  const [friends, setFriends] = useState([]);
  const [error, setError] = useState(null);
  const [ymaps, setYmaps] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);

  // Загрузка скрипта API Яндекс.Карт
  useEffect(() => {
    const loadYMapsScript = () => {
      try {
        // Проверяем, не загружен ли уже скрипт
        if (window.ymaps) {
          if (DEBUG) console.log('Яндекс.Карты уже загружены');
          window.ymaps.ready(() => {
            setYmaps(window.ymaps);
            setYMapsReady(true);
          });
          return;
        }

        const protocol = IS_HTTPS ? 'https' : 'http';
        const apiUrl = `${protocol}://api-maps.yandex.ru/2.1/?apikey=${YANDEX_MAPS_API_KEY}&lang=ru_RU`;
        
        // Создаем скрипт
        const script = document.createElement('script');
        script.src = apiUrl;
        script.async = true;
        script.onload = () => {
          if (DEBUG) console.log('Скрипт Яндекс.Карт загружен');
          window.ymaps.ready(() => {
            if (DEBUG) console.log('API Яндекс.Карт готово к использованию');
            setYmaps(window.ymaps);
            setYMapsReady(true);
          });
        };
        script.onerror = (e) => {
          console.error('Ошибка загрузки API Яндекс.Карт:', e);
          setError('Не удалось загрузить карту. Пожалуйста, проверьте подключение к интернету.');
        };
        
        document.head.appendChild(script);
      } catch (err) {
        console.error('Ошибка при загрузке скрипта Яндекс.Карт:', err);
        setError('Произошла ошибка при загрузке карты. Пожалуйста, попробуйте позже.');
      }
    };

    loadYMapsScript();

    // Функция очистки
    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.destroy();
        mapInstanceRef.current = null;
      }
    };
  }, []);

  // Загрузка данных о местоположении и друзьях
  useEffect(() => {
    const fetchData = async () => {
      if (!yMapsReady) return;
      
      setIsLoading(true);
      try {
        const initData = telegramWebApp?.initData || '';
        const headers = { 'Content-Type': 'application/json' };
        if (initData) headers['Authorization'] = `Bearer ${initData}`;
        
        // Загрузка местоположения пользователя
        try {
          const userLocationResponse = await fetch(`${API_URL}/locations/user`, {
            method: 'GET',
            headers,
            credentials: 'include'
          });
          
          if (userLocationResponse.ok) {
            const locationData = await userLocationResponse.json();
            if (locationData.latitude && locationData.longitude) {
              setLocation([locationData.latitude, locationData.longitude]);
              if (DEBUG) console.log('Получены координаты пользователя:', locationData);
            } else {
              if (DEBUG) console.log('Координаты пользователя не найдены, используем значения по умолчанию');
            }
          } else {
            if (DEBUG) console.log('Ошибка запроса местоположения пользователя:', userLocationResponse.status);
          }
        } catch (locError) {
          console.error('Ошибка при загрузке местоположения пользователя:', locError);
        }
        
        // Загрузка местоположения друзей
        try {
          const friendsResponse = await fetch(`${API_URL}/locations/friends`, {
            method: 'GET',
            headers,
            credentials: 'include'
          });
          
          if (friendsResponse.ok) {
            const data = await friendsResponse.json();
            if (data.friends && Array.isArray(data.friends)) {
              setFriends(data.friends);
              if (DEBUG) console.log('Получен список друзей:', data.friends.length);
            } else {
              setFriends([]);
              if (DEBUG) console.log('Список друзей пуст или не в корректном формате');
            }
          } else {
            console.error('Ошибка запроса списка друзей:', friendsResponse.status);
            if (IS_DEVELOPMENT || DEBUG) {
              setFriends(LOCAL_MOCK_FRIENDS);
              if (DEBUG) console.log('Используем тестовые данные о друзьях');
            }
          }
        } catch (friendsError) {
          console.error('Ошибка при загрузке друзей:', friendsError);
          if (IS_DEVELOPMENT || DEBUG) {
            setFriends(LOCAL_MOCK_FRIENDS);
            if (DEBUG) console.log('Используем тестовые данные о друзьях из-за ошибки');
          }
        }
      } catch (e) {
        console.error('Общая ошибка при загрузке данных:', e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    
    // Настраиваем интервал для периодического обновления данных
    const intervalId = setInterval(fetchData, UPDATE_INTERVAL);
    
    return () => clearInterval(intervalId);
  }, [yMapsReady, telegramWebApp]);

  // Инициализация карты после загрузки API и данных
  useEffect(() => {
    if (!yMapsReady || !ymaps || isLoading) {
      return;
    }

    try {
      // Создаем карту
      if (!mapInstanceRef.current && mapRef.current) {
        if (DEBUG) console.log('Создаем экземпляр карты');
        
        mapInstanceRef.current = new ymaps.Map(mapRef.current, {
          center: location,
          zoom: DEFAULT_ZOOM,
          controls: ['zoomControl', 'geolocationControl']
        });
        
        // Добавляем метку пользователя
        const userPlacemark = new ymaps.Placemark(location, {
          hintContent: 'Вы здесь'
        }, {
          preset: 'islands#blueCircleDotIcon'
        });
        mapInstanceRef.current.geoObjects.add(userPlacemark);
        
        // Добавляем метки друзей
        friends.forEach(friend => {
          if (friend.latitude && friend.longitude) {
            const friendCoordinates = [friend.latitude, friend.longitude];
            
            // Создаем балун с информацией о друге
            const balloonContent = `
              <div class="friend-balloon">
                <div class="friend-header">
                  <img src="${friend.avatar_url || 'https://via.placeholder.com/30'}" alt="${friend.first_name}" class="friend-avatar">
                  <div class="friend-name">${friend.first_name} ${friend.last_name || ''}</div>
                </div>
                <div class="friend-info">
                  <div>Последнее обновление: ${formatLastSeen(friend.last_updated)}</div>
                  ${friend.distance ? `<div>Расстояние: ${formatDistance(friend.distance)}</div>` : ''}
                </div>
              </div>
            `;
            
            // Создаем метку друга
            const friendPlacemark = new ymaps.Placemark(friendCoordinates, {
              hintContent: `${friend.first_name} ${friend.last_name || ''}`,
              balloonContent: balloonContent
            }, {
              iconLayout: 'default#image',
              iconImageHref: friend.avatar_url || 'https://via.placeholder.com/40',
              iconImageSize: [40, 40],
              iconImageOffset: [-20, -20],
              balloonCloseButton: true,
              hideIconOnBalloonOpen: false
            });
            
            mapInstanceRef.current.geoObjects.add(friendPlacemark);
          }
        });
      } else if (mapInstanceRef.current) {
        // Обновляем карту, если она уже существует
        if (DEBUG) console.log('Обновляем карту');
        
        // Обновляем центр карты
        mapInstanceRef.current.setCenter(location);
        
        // Очищаем все метки
        mapInstanceRef.current.geoObjects.removeAll();
        
        // Добавляем метку пользователя
        const userPlacemark = new ymaps.Placemark(location, {
          hintContent: 'Вы здесь'
        }, {
          preset: 'islands#blueCircleDotIcon'
        });
        mapInstanceRef.current.geoObjects.add(userPlacemark);
        
        // Добавляем метки друзей
        friends.forEach(friend => {
          if (friend.latitude && friend.longitude) {
            const friendCoordinates = [friend.latitude, friend.longitude];
            
            // Создаем балун с информацией о друге
            const balloonContent = `
              <div class="friend-balloon">
                <div class="friend-header">
                  <img src="${friend.avatar_url || 'https://via.placeholder.com/30'}" alt="${friend.first_name}" class="friend-avatar">
                  <div class="friend-name">${friend.first_name} ${friend.last_name || ''}</div>
                </div>
                <div class="friend-info">
                  <div>Последнее обновление: ${formatLastSeen(friend.last_updated)}</div>
                  ${friend.distance ? `<div>Расстояние: ${formatDistance(friend.distance)}</div>` : ''}
                </div>
              </div>
            `;
            
            // Создаем метку друга
            const friendPlacemark = new ymaps.Placemark(friendCoordinates, {
              hintContent: `${friend.first_name} ${friend.last_name || ''}`,
              balloonContent: balloonContent
            }, {
              iconLayout: 'default#image',
              iconImageHref: friend.avatar_url || 'https://via.placeholder.com/40',
              iconImageSize: [40, 40],
              iconImageOffset: [-20, -20],
              balloonCloseButton: true,
              hideIconOnBalloonOpen: false
            });
            
            mapInstanceRef.current.geoObjects.add(friendPlacemark);
          }
        });
      }
    } catch (e) {
      console.error('Ошибка при инициализации или обновлении карты:', e);
      setError('Произошла ошибка при отображении карты. Пожалуйста, обновите страницу.');
    }
  }, [yMapsReady, ymaps, location, friends, isLoading]);

  // Обработка изменения размера окна
  useEffect(() => {
    const handleResize = () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.container.fitToViewport();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="map-page">
      {error && <div className="error-banner">{error}</div>}
      
      {isLoading && !mapInstanceRef.current && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Загрузка карты...</div>
        </div>
      )}
      
      <div 
        ref={mapRef} 
        className="map-container" 
        style={{ width: '100%', height: 'calc(100vh - 60px)' }}
      ></div>
      
      <style jsx>{`
        .map-page {
          position: relative;
          width: 100%;
          height: calc(100vh - 60px);
        }
        
        .map-container {
          width: 100%;
          height: 100%;
        }
        
        .error-banner {
          position: absolute;
          top: 10px;
          left: 10px;
          right: 10px;
          background-color: rgba(244, 67, 54, 0.9);
          color: white;
          padding: 10px;
          z-index: 10;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          text-align: center;
          font-weight: 500;
        }
        
        .loading-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.8);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          z-index: 5;
        }
        
        .loading-spinner {
          border: 4px solid rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          border-top: 4px solid #007bff;
          width: 40px;
          height: 40px;
          animation: spin 1s linear infinite;
        }
        
        .loading-text {
          margin-top: 15px;
          font-size: 16px;
          color: #333;
        }
        
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        
        /* Стили для балуна друга */
        :global(.friend-balloon) {
          padding: 10px;
          max-width: 200px;
        }
        
        :global(.friend-header) {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
        }
        
        :global(.friend-avatar) {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;
        }
        
        :global(.friend-name) {
          font-weight: bold;
        }
        
        :global(.friend-info) {
          font-size: 12px;
          color: #666;
        }
      `}</style>
    </div>
  );
};

export default MapPage;