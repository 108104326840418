import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';

// Импорт страниц
import MapPage from './pages/MapPage';
import FriendsPage from './pages/FriendsPage';
import SettingsPage from './pages/SettingsPage';

// Импорт компонентов
import Navigation from './components/Navigation';

// Импорт централизованных настроек
import { IS_DEVELOPMENT, DEBUG } from './config';

// Контекст Telegram WebApp
const TelegramContext = React.createContext(null);
export const useTelegram = () => React.useContext(TelegramContext);

function App() {
  const [telegramWebApp, setTelegramWebApp] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    // Включение отладочной информации
    if (DEBUG) {
      console.log('Debug mode enabled');
    }
    
    const initTelegramApp = () => {
      // Проверка наличия объекта window.Telegram.WebApp
      if (!window.Telegram || !window.Telegram.WebApp) {
        if (DEBUG) {
          console.log('Telegram WebApp not found, using mock in debug mode');
          createMockWebApp();
        } else {
          setError('Telegram WebApp not available. Please open this app through Telegram.');
        }
        return;
      }
      
      try {
        const tg = window.Telegram.WebApp;
        
        // Вывод версии WebApp в режиме отладки
        if (DEBUG) {
          console.log('Telegram WebApp version:', tg.version);
          console.log('Platform:', tg.platform);
          console.log('Initial theme:', tg.colorScheme);
        }
        
        // Сообщаем Telegram, что приложение готово
        tg.ready();
        
        // Разворачиваем WebApp на весь экран
        tg.expand();
        
        // Применяем тему Telegram
        setTheme(tg.colorScheme || 'light');
        
        // Добавляем обработчик события изменения темы
        tg.onEvent('themeChanged', () => {
          if (DEBUG) {
            console.log('Theme changed to:', tg.colorScheme);
          }
          setTheme(tg.colorScheme || 'light');
        });
        
        // Сохраняем объект WebApp
        setTelegramWebApp(tg);
        
        // Обработка данных инициализации
        processInitData(tg);
      } catch (err) {
        console.error('Error initializing Telegram WebApp:', err);
        if (DEBUG) {
          createMockWebApp();
        } else {
          setError(`Error initializing: ${err.message}`);
        }
      }
    };
    
    // Обработка initData из Telegram
    const processInitData = (tg) => {
      if (DEBUG) {
        console.log('Processing init data');
      }
      
      try {
        // В Mini Apps initDataUnsafe может быть доступен даже если initData пуст
        if (tg.initDataUnsafe && tg.initDataUnsafe.user) {
          const userData = {
            id: tg.initDataUnsafe.user.id,
            username: tg.initDataUnsafe.user.username || '',
            first_name: tg.initDataUnsafe.user.first_name || '',
            last_name: tg.initDataUnsafe.user.last_name || '',
            photo_url: tg.initDataUnsafe.user.photo_url || ''
          };
          
          if (DEBUG) {
            console.log('User data from Telegram:', userData);
          }
          
          setUser(userData);
          setError(null);
        } else if (DEBUG) {
          // В режиме отладки используем тестовые данные
          setTestUserData();
        } else {
          setError('Не удалось получить данные пользователя. Перезапустите приложение в Telegram.');
        }
      } catch (err) {
        console.error('Error processing init data:', err);
        if (DEBUG) {
          setTestUserData();
        } else {
          setError(`Error processing user data: ${err.message}`);
        }
      }
    };
    
    // Функция для использования тестовых данных пользователя в режиме отладки
    const setTestUserData = () => {
      console.log('Using test user data in debug mode');
      setUser({
        id: 123456789,
        username: 'test_user',
        first_name: 'Test',
        last_name: 'User',
        photo_url: ''
      });
      setError(null);
    };
    
    // Функция для создания заглушки WebApp в режиме отладки
    const createMockWebApp = () => {
      console.log('Creating mock WebApp for debugging');
      const mockWebApp = {
        ready: () => console.log('Mock WebApp: ready called'),
        expand: () => console.log('Mock WebApp: expand called'),
        close: () => console.log('Mock WebApp: close called'),
        colorScheme: 'light',
        version: '6.0',
        platform: 'web',
        initData: '',
        initDataUnsafe: {
          user: {
            id: 123456789,
            username: 'test_user',
            first_name: 'Test',
            last_name: 'User',
            photo_url: ''
          }
        },
        showPopup: (params) => {
          console.log('Mock WebApp: showPopup called with', params);
          return Promise.resolve(true);
        },
        showAlert: (message) => {
          console.log('Mock WebApp: showAlert called with', message);
          return Promise.resolve();
        },
        showConfirm: (message) => {
          console.log('Mock WebApp: showConfirm called with', message);
          return Promise.resolve(true);
        },
        sendData: (data) => {
          console.log('Mock WebApp: sendData called with', data);
        },
        isExpanded: true,
        onEvent: (eventName, callback) => {
          console.log(`Mock WebApp: onEvent registered for "${eventName}"`);
          return () => console.log(`Mock WebApp: onEvent unsubscribed for "${eventName}"`);
        },
        MainButton: {
          show: () => console.log('Mock WebApp: MainButton.show called'),
          hide: () => console.log('Mock WebApp: MainButton.hide called'),
          setText: (text) => console.log(`Mock WebApp: MainButton.setText called with ${text}`),
          onClick: (callback) => console.log('Mock WebApp: MainButton.onClick registered')
        }
      };
      
      setTelegramWebApp(mockWebApp);
      setTestUserData();
    };
    
    // Инициализация приложения
    initTelegramApp();
    
    // Устанавливаем флаг окончания загрузки
    setIsLoading(false);
    
    // Очистка обработчиков событий при размонтировании компонента
    return () => {
      if (telegramWebApp && typeof telegramWebApp.onEvent === 'function') {
        // Ничего не делаем, так как onEvent в Telegram WebApp API 
        // возвращает функцию для отписки, которую мы не сохраняли
        // Эта заглушка добавлена для совместимости с будущими версиями
      }
    };
  }, []);

  // Если приложение загружается, показываем загрузочный экран
  if (isLoading) {
    return <div className="loading">Загрузка приложения...</div>;
  }

  // Если есть ошибка, показываем сообщение об ошибке
  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <TelegramContext.Provider value={{ telegramWebApp, user, theme }}>
      <div className={`app ${theme}`}>
        <Router>
          <div className="app-content">
            <Routes>
              <Route path="/" element={<MapPage />} />
              <Route path="/friends" element={<FriendsPage />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
          <Navigation />
        </Router>
      </div>
    </TelegramContext.Provider>
  );
}

export default App;