import React, { useState, useEffect } from 'react';
import { useTelegram } from '../App';
import { API_URL, IS_DEVELOPMENT, MOCK_SETTINGS, HIDE_OPTIONS, DEBUG } from '../config';

const SettingsPage = () => {
  const { telegramWebApp, user, theme } = useTelegram();
  const [privacySettings, setPrivacySettings] = useState({
    isHidden: false,
    hiddenUntil: null
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Загрузка настроек пользователя
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        if (DEBUG) {
          console.log('Загрузка настроек приватности...');
        }
        
        // Получаем настройки пользователя через API
        const initData = telegramWebApp?.initData || '';
        let requestHeaders = {
          'Content-Type': 'application/json'
        };
        
        // Добавляем заголовок Authorization только если initData не пуст
        if (initData) {
          requestHeaders['Authorization'] = `Bearer ${initData}`;
        }
        
        const response = await fetch(`${API_URL}/users/me`, {
          method: 'GET',
          headers: requestHeaders,
          credentials: 'include' // Добавляем credentials для поддержки cookie-аутентификации
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setPrivacySettings({
          isHidden: data.is_hidden || false,
          hiddenUntil: data.hidden_until || null
        });
        
        setLoading(false);
        setError(null);
      } catch (err) {
        console.error('Ошибка при загрузке настроек:', err);
        
        // Для отладки: используем тестовые данные только в режиме разработки
        if (IS_DEVELOPMENT || DEBUG) {
          if (DEBUG) {
            console.log('Использование тестовых данных настроек в режиме отладки');
          }
          setPrivacySettings(MOCK_SETTINGS);
          setError(null); // Не показываем ошибку в режиме разработки при использовании тестовых данных
        } else {
          setError(`Ошибка при загрузке настроек: ${err.message}`);
        }
        setLoading(false);
      }
    };

    if (telegramWebApp || IS_DEVELOPMENT || DEBUG) {
      fetchSettings();
    } else {
      setError('Telegram WebApp недоступен');
      setLoading(false);
    }
  }, [telegramWebApp]);

  // Обработчик изменения настроек приватности
  const handleHideLocationChange = async (duration) => {
    try {
      setLoading(true);

      // Вычисляем время, до которого пользователь будет скрыт
      const now = new Date();
      const hiddenUntil = new Date(now.getTime() + duration);

      if (DEBUG) {
        console.log('Обновление настроек приватности, скрытие до:', hiddenUntil);
      }

      // Отправляем запрос на обновление настроек через API
      const initData = telegramWebApp?.initData || '';
      let requestHeaders = {
        'Content-Type': 'application/json'
      };
      
      // Добавляем заголовок Authorization только если initData не пуст
      if (initData) {
        requestHeaders['Authorization'] = `Bearer ${initData}`;
      }
      
      const response = await fetch(`${API_URL}/users/privacy`, {
        method: 'POST',
        headers: requestHeaders,
        credentials: 'include',
        body: JSON.stringify({
          is_hidden: true,
          hidden_until: hiddenUntil.toISOString()
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Обновляем локальное состояние
      setPrivacySettings({
        isHidden: true,
        hiddenUntil
      });

      // Отображаем уведомление об успешном обновлении
      if (telegramWebApp) {
        telegramWebApp.showPopup({
          title: 'Настройки обновлены',
          message: `Ваша геолокация скрыта до ${hiddenUntil.toLocaleString()}`,
          buttons: [{ type: 'ok' }]
        });
      } else if (DEBUG || IS_DEVELOPMENT) {
        alert(`Настройки обновлены. Геолокация скрыта до ${hiddenUntil.toLocaleString()}`);
      }
    } catch (err) {
      console.error('Ошибка при обновлении настроек:', err);
      
      if (IS_DEVELOPMENT || DEBUG) {
        // В режиме разработки обновляем локальное состояние напрямую
        const now = new Date();
        const hiddenUntil = new Date(now.getTime() + duration);
        setPrivacySettings({
          isHidden: true,
          hiddenUntil
        });
        setError(null);
        if (DEBUG) {
          console.log('Локальное обновление настроек в режиме отладки');
        }
        alert(`Тестовый режим: геолокация скрыта до ${hiddenUntil.toLocaleString()}`);
      } else {
        setError(`Ошибка при обновлении настроек: ${err.message}`);
        
        // Показываем ошибку через Telegram WebApp
        if (telegramWebApp) {
          telegramWebApp.showPopup({
            title: 'Ошибка',
            message: `Не удалось обновить настройки: ${err.message}`,
            buttons: [{ type: 'ok' }]
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  // Обработчик для отключения скрытия
  const handleDisableHiding = async () => {
    try {
      setLoading(true);

      if (DEBUG) {
        console.log('Отключение скрытия геолокации');
      }

      // Отправляем запрос на обновление настроек через API
      const initData = telegramWebApp?.initData || '';
      let requestHeaders = {
        'Content-Type': 'application/json'
      };
      
      // Добавляем заголовок Authorization только если initData не пуст
      if (initData) {
        requestHeaders['Authorization'] = `Bearer ${initData}`;
      }
      
      const response = await fetch(`${API_URL}/users/privacy`, {
        method: 'POST',
        headers: requestHeaders,
        credentials: 'include',
        body: JSON.stringify({
          is_hidden: false,
          hidden_until: null
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Обновляем локальное состояние
      setPrivacySettings({
        isHidden: false,
        hiddenUntil: null
      });

      // Отображаем уведомление об успешном обновлении
      if (telegramWebApp) {
        telegramWebApp.showPopup({
          title: 'Настройки обновлены',
          message: 'Ваша геолокация теперь видна друзьям',
          buttons: [{ type: 'ok' }]
        });
      } else if (DEBUG || IS_DEVELOPMENT) {
        alert('Геолокация теперь видна друзьям');
      }
    } catch (err) {
      console.error('Ошибка при обновлении настроек:', err);
      
      if (IS_DEVELOPMENT || DEBUG) {
        // В режиме разработки обновляем локальное состояние напрямую
        setPrivacySettings({
          isHidden: false,
          hiddenUntil: null
        });
        setError(null);
        alert('Тестовый режим: геолокация теперь видна');
      } else {
        setError(`Ошибка при обновлении настроек: ${err.message}`);
        
        // Показываем ошибку через Telegram WebApp
        if (telegramWebApp) {
          telegramWebApp.showPopup({
            title: 'Ошибка',
            message: `Не удалось обновить настройки: ${err.message}`,
            buttons: [{ type: 'ok' }]
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="loading">Загрузка настроек...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  // Проверяем, активно ли сейчас скрытие
  const isCurrentlyHidden = privacySettings.isHidden && privacySettings.hiddenUntil && new Date(privacySettings.hiddenUntil) > new Date();

  return (
    <div className={`settings-page ${theme}`}>
      <div className="settings-header">
        <h1>Настройки</h1>
      </div>

      <div className="settings-section">
        <h2>Приватность</h2>
        
        {isCurrentlyHidden ? (
          <div className="active-hiding">
            <p className="status-text">
              Ваша геолокация скрыта до {new Date(privacySettings.hiddenUntil).toLocaleString()}
            </p>
            <button className="disable-button" onClick={handleDisableHiding}>
              Отключить скрытие
            </button>
          </div>
        ) : (
          <div className="privacy-options">
            <p className="status-text">Вы сейчас видимы для друзей</p>
            <p className="option-label">Скрыть мою геолокацию на:</p>
            <div className="hide-options">
              {HIDE_OPTIONS.map((option) => (
                <button
                  key={option.value}
                  className="hide-button"
                  onClick={() => handleHideLocationChange(option.value)}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="settings-section">
        <h2>О приложении</h2>
        <p className="about-text">
          PinGram - локатор друзей для Telegram.<br />
          Версия 0.1.0
        </p>
      </div>

      <style jsx>{`
        .settings-page {
          padding: 16px;
          min-height: calc(100vh - 60px);
        }
        
        .settings-header {
          margin-bottom: 24px;
        }
        
        .settings-header h1 {
          font-size: 20px;
          margin: 0;
        }
        
        .settings-section {
          background-color: var(--tg-theme-secondary-bg-color, #f0f0f0);
          border-radius: 12px;
          padding: 16px;
          margin-bottom: 20px;
        }
        
        .dark .settings-section {
          background-color: var(--tg-theme-secondary-bg-color, #333333);
        }
        
        .settings-section h2 {
          font-size: 18px;
          margin: 0 0 16px;
        }
        
        .status-text {
          margin-bottom: 16px;
          font-weight: bold;
        }
        
        .active-hiding .status-text {
          color: var(--tg-theme-button-color, #3390ec);
        }
        
        .option-label {
          margin-bottom: 12px;
        }
        
        .hide-options {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 10px;
        }
        
        .hide-button {
          background-color: var(--tg-theme-button-color, #3390ec);
          color: var(--tg-theme-button-text-color, #ffffff);
          border: none;
          border-radius: 8px;
          padding: 10px;
          font-size: 14px;
          cursor: pointer;
        }
        
        .disable-button {
          background-color: #f44336;
          color: white;
          border: none;
          border-radius: 8px;
          padding: 10px;
          font-size: 14px;
          width: 100%;
          cursor: pointer;
        }
        
        .about-text {
          line-height: 1.5;
          color: var(--tg-theme-hint-color, #999999);
        }
      `}</style>
    </div>
  );
};

export default SettingsPage;