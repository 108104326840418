import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTelegram } from '../App';

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { theme } = useTelegram();

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div className={`navigation ${theme}`}>
      <div
        className={`nav-item ${isActive('/friends') ? 'active' : ''}`}
        onClick={() => navigate('/friends')}
      >
        <div className="nav-icon">👥</div>
        <div className="nav-label">Друзья</div>
      </div>
      <div
        className={`nav-item ${isActive('/') ? 'active' : ''}`}
        onClick={() => navigate('/')}
      >
        <div className="nav-icon">🗺️</div>
        <div className="nav-label">Карта</div>
      </div>
      <div
        className={`nav-item ${isActive('/settings') ? 'active' : ''}`}
        onClick={() => navigate('/settings')}
      >
        <div className="nav-icon">⚙️</div>
        <div className="nav-label">Настройки</div>
      </div>
    </div>
  );
};

export default Navigation;